import * as React from 'react';
import * as svg from '../img/logo.svg';
import '../styles/index.css';
import { Icon, Tooltip, Tabs, Button } from 'antd';
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image';
import createDefaultLayout from '../layouts/defaultLayout';
import { GatsbyPageProps } from '../types';
import {
    getPressableScaleContainer,
    getHoverableScaleContainer,
} from '../util/animation';

const TabPane = Tabs.TabPane;
const PressableScaleContainer = getPressableScaleContainer(1.5);
const HoverableScaleContainer = getHoverableScaleContainer(1.5);

export default class IndexPage extends React.Component<GatsbyPageProps, {}> {
    render() {
        const Layout = createDefaultLayout();
        return (
            <StaticQuery
                query={graphql`
                  query {
                    placeholderImage: file(relativePath: { eq: "logo.png" }) {
                      childImageSharp {
                        fixed(width: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                    contact: markdownRemark(frontmatter: { path: { eq: "/contact" } }) {
                        frontmatter {
                            text
                            number
                        }
                    }
                    tabs: allMarkdownRemark(
                        filter: { frontmatter: { type: { eq: "tab" } } }
                        sort: { fields: [frontmatter___position], order: ASC }
                        limit: 10
                    ) {
                        edges {
                            node {
                                html
                                frontmatter {
                                    title
                                    path
                                    position
                                }
                            }
                        }
                    }
                  }
                `}
                render={data => (
                    <Layout location={this.props.location}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '80vh',
                                flexDirection: 'column',
                            }}
                        >
                            <Img fixed={data.placeholderImage.childImageSharp.fixed} />
                            <p
                                style={{
                                    paddingTop: 20,
                                    fontSize: 40,
                                    textAlign: 'center',
                                    color: '008f95',
                                    paddingBottom: 0,
                                    marginBottom: 15,
                                }}
                            >
                                Tele-Testing
                            </p>
                            <p>
                                {data.contact.frontmatter.text}{' '}
                                <a href={`tel:${data.contact.frontmatter.number}`}>
                                    {data.contact.frontmatter.number}
                                </a>
                            </p>
                            <Button
                                type="primary"
                                icon="clock-circle"
                                size="large"
                                href="https://my.setmore.com/bookingpage/9c4fa61c-6424-4d62-908b-f4c16a2098a3"
                            >
                                Book Now!
                            </Button>
                            <div style={{ marginBottom: 20 }} />
                            <Tabs defaultActiveKey="1" type="card">
                                {
                                    data.tabs.edges.map(item => (
                                        <TabPane tab={item.node.frontmatter.title} key={item.node.frontmatter.position}>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item.node.html }}
                                            />
                                        </TabPane>
                                    ))
                                }
                            </Tabs>
                        </div>
                    </Layout>
                )}
            />
        );
    }
}
